import Typography from '../Typography';
import LandingLayout from './LandingLayout';
import ServicesAnimation from './ServicesAnimation';

const backgroundImage = '../images/home_1.jpeg';

export default function Landing() {

  return (
    <LandingLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundPositionY: -10
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />

      <Typography  color="inherit" align="left" variant="h4">
        Teresa's Consulting Services
      </Typography>

      <Typography
        color="inherit"
        align="left"
        variant="h3"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        Your Financial Partner Everywhere
      </Typography>
      <ServicesAnimation/>
      <Typography variant="body1" color="inherit" sx={{ mt: 2 }}>
        Elevate Your Financial Horizon: Empowering Businesses and Individuals with Expert Solutions
      </Typography>
    </LandingLayout >
  );
}