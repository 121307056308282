import React from "react";
import { Typography, Box, Link, Avatar } from "@mui/material";
import BusinessSharpIcon from '@mui/icons-material/BusinessSharp';
import MailSharpIcon from '@mui/icons-material/MailSharp';
import LocalPhoneSharpIcon from '@mui/icons-material/LocalPhoneSharp';
import { deepOrange } from "@mui/material/colors";

interface FooterProps {
  address: string;
  email: string;
  phone1: string;
  phone2: string;
}

const Footer: React.FC<FooterProps> = ({ address, email, phone1, phone2 }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#f0f0f0",
        padding: "20px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <Avatar sx={{ bgcolor: deepOrange[500], marginRight: "5px" }} variant="rounded">
          <BusinessSharpIcon sx={{ marginRight: "5px" }} />
        </Avatar>
        <Typography variant="body1">{address}</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <Avatar sx={{ bgcolor: deepOrange[500], marginRight: "5px" }} variant="rounded">
          <MailSharpIcon />
        </Avatar>
        <Typography variant="body1">
          <Link color="inherit" href={`mailto:${email}`}>{email}</Link>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar sx={{ bgcolor: deepOrange[500], marginRight: "5px" }} variant="rounded">
          <LocalPhoneSharpIcon />
        </Avatar>
        <Typography variant="body1">
          <Link color="inherit" href={`tel:${phone1}`}>{phone1}</Link> | <Link color="inherit" href={`tel:${phone2}`}>{phone2}</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;

