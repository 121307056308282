import React from "react";
import { Typography, Box, Grid, Avatar } from "@mui/material";

interface ServicesGridItemProps {
    title: string;
    subtitle: string;
    text: string;
    svg: string;
}

const ServicesGridItem: React.FC<ServicesGridItemProps> = ({ title, subtitle, text, svg }) => {
    return (

        <Grid item xs={12} sm={6}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Avatar src={svg} sx={{ width: 150, height: 150, marginBottom: 2 }} variant="square" />
                    </Box>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'grey' }}>{title}</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'grey' }}>{subtitle}</Typography>
                    <Typography variant="body2">{text}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ServicesGridItem;

