import { Box, CssBaseline, ThemeOptions, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { routes as appRoutes } from "./routes";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function App() {

  const themeOptions: ThemeOptions = {
    palette: {
      primary: {
        main: '#ffecb3',
      },
      secondary: {
        main: '#ffa000',
      },
      background: {
        default: '#fff8e1',
        paper: '#ffe082',
      },
      text: {
        primary: '#1e1d1d',
      },
    },
    typography: {
      fontFamily: 'Roboto',
      fontSize: 18,
      fontWeightLight: 600,
      fontWeightRegular: 400,
    },
  };

  const theme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box height="100vh" display="flex" flexDirection="column">
        <Router>
          <Navbar />
          <Routes>
            {appRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Routes>
        </Router>
        <Footer
          email="teremed@sbcglobal.net"
          phone1="(714) 828-9165"
          phone2=" (714) 906-1150"
          address="3070 W. Lincoln Ave Ste M, Anaheim, CA 92801"
        />
      </Box>
    </ThemeProvider>
  );
}

export default App;