import { ReactElement, FC } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import TestimonialsGridItem from "../components/TestimonialsGridItem";

const Testimonials: FC<any> = (): ReactElement => {

    const theme = useTheme();

    return (
        <Box sx={{
            flexGrow: 1,
            backgroundColor: 'whitesmoke',
            padding: { xs: 2, md: 5 }, // Adjust padding for different screen sizes
        }}>

            <Grid
                container
                spacing={2}
                sx={{
                    marginTop: 2,
                    flexDirection: "row",
                    [theme.breakpoints.down("xs")]: {
                        flexDirection: "column"
                    }
                }}
            >
                <Grid item xs={12} sm={6}>
                    <Typography variant="h3" align="left">
                        Testimonials
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="h5" align="center" sx={{ fontWeight: 'bold', color: 'grey' }}>
                        What our clients say about us
                    </Typography>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={2}
                sx={{
                    marginTop: 10,
                    flexDirection: "row",
                    [theme.breakpoints.down("xs")]: {
                        flexDirection: "column"
                    }
                }}
            >
                {/* First Grid Item */}
                <TestimonialsGridItem
                    text="Es muy amable la señora, todos son muy amables y me quedé satisfecha con el servicio que me dió. Fue muy rápido el proceso y a las dos semanas obtuve mi rembolso."
                    name="Linda G."
                />

                {/* Second Grid Item */}
                <TestimonialsGridItem
                    text="10/10! Good service, she’s like family!"
                    name="Angel A."
                />

                {/* Third Grid Item */}
                <TestimonialsGridItem
                    text="Teresa has been an invaluable asset in handling my taxes since I arrived in the US. Her expertise extends beyond tax preparation, as she also assisted with translating official documents. Her professionalism and attention to detail make her an exceptional financial expert. Highly recommended."
                    name="Ivan C."
                />

            </Grid>
        </Box>
    );
};

export default Testimonials;