import React from "react";
import { Typography, Box, Grid, Divider, Chip } from "@mui/material";


interface MissionBoxProps {
    title: string;
    text1: string;
    text2: string;
    text3: string;
    text4: string;
    picture_url: string;
    picture_alt: string;
}

const MissionBox: React.FC<MissionBoxProps> = ({ title, text1, text2, text3, text4, picture_url, picture_alt }) => {
    return (

        <Grid item xs={12} md={6} textAlign="center" alignContent="center">
            <Typography color="inherit" variant="h4" gutterBottom>{title}</Typography>
            <Divider sx={{ marginBottom: 2 }} />

            <Grid container spacing={2} columns={2} sx={{ paddingLeft: { xs: 0, md: 25 } }}>
                <Grid item>
                    <Chip label={text1} sx={{ margin: 1 }} />
                    <Chip label={text2} />
                </Grid>
                <Grid item>
                    <Chip label={text3} sx={{ margin: 1 }} />
                    <Chip label={text4} />
                </Grid>
            </Grid>

            <Box
                component="img"
                sx={{
                    marginTop: 5,
                    height: 400,
                    width: 600,
                    maxHeight: { xs: 233, md: 400 },
                    maxWidth: { xs: 350, md: 600 },
                    borderRadius: 10,
                }}
                alt={picture_alt}
                src={picture_url}
            />

        </Grid>
    );
};

export default MissionBox;

