import { ReactElement, FC } from "react";
import { Box, Typography, Grid, Divider, Avatar } from "@mui/material";

const About: FC<any> = (): ReactElement => {
    return (
        <Grid container columnSpacing={10} sx={{
            flexGrow: 1,
            backgroundColor: 'whitesmoke',
            padding: { xs: 2, md: 15 }, // Adjust padding for different screen sizes
        }}>
            {/* First column */}
            <Grid item xs={12} md={3}>
                <Box sx={{ textAlign: 'center', marginBottom: { xs: 4, md: 0 } }}> {/* Add marginBottom for small screens */}
                    <Typography variant="h4" gutterBottom>Your expert with all things business</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Avatar src="../images/about_1.jpeg" sx={{ width: 300, height: 300, marginBottom: 2 }} />
                    </Box>
                </Box>
            </Grid>

            {/* Second column */}
            <Grid item xs={12} md={9}>

                <Typography variant="body1" gutterBottom sx={{ marginBottom: 2 }}>
                    Welcome to Teresa's Consulting Services, your one-stop solution for expert financial and administrative ​support!
                </Typography>

                <Typography variant="body1" gutterBottom>
                    Meet Teresa Ramos, the skilled professional offering a range of services in English and Spanish. From year-round income tax assistance to notary public services, loans, and refinancing, property transactions, translations, and ITIN# processing – Teresa has the credentials and dedication to handle it all. Choose Teresa for personalized, certified, and seamless financial solutions tailored to your needs. Your success is our priority at Teresa's Consulting Services.
                </Typography>

                <Divider sx={{ marginBottom: 5, marginTop: 5 }} /> {/* Add marginBottom for spacing */}

                <Typography variant="body1" gutterBottom sx={{ marginBottom: 2 }}>
                    Bienvenido a los Servicios de Consultoría de Teresa, tu solución integral para apoyo financiero y administrativo de alta calidad!
                </Typography>

                <Typography variant="body1">
                    Conoce a Teresa Ramos, la profesional experta que ofrece una variedad de servicios en inglés y español. Desde asistencia tributaria durante todo el año hasta servicios de notario público, préstamos y refinanciamiento, transacciones de propiedades, traducciones y procesamiento de ITIN#; Teresa cuenta con las credenciales y la dedicación para manejarlo todo. Elige a Teresa para soluciones financieras personalizadas, certificadas y sin complicaciones adaptadas a tus necesidades. Tu éxito es nuestra prioridad en los Servicios de Consultoría de Teresa.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default About;
