import React from "react";
import { Typography, Grid, Box } from "@mui/material";

interface TestimonialsGridItemProps {
    text: string;
    name: string;
}

const TestimonialsGridItem: React.FC<TestimonialsGridItemProps> = ({ text, name }) => {
    return (

        <Grid item xs={12} sm={4}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', padding: { xs: 5, md: 10 } }}>
                <Typography variant="body1" gutterBottom>{text}</Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'grey', marginTop: 'auto' }}>- {name}</Typography>
            </Box>
        </Grid>
    );
};

export default TestimonialsGridItem;

