import { ReactElement, FC } from "react";
import Landing from "../components/home/Landing";

const Home: FC<any> = (): ReactElement => {
    return (
        <Landing />
    );
};

export default Home;
