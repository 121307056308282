import { TypeAnimation } from 'react-type-animation';

const ServicesAnimation = () => {
  return (
    <TypeAnimation
      sequence={[
        'Income Tax all year',
        1000,
        'Notary Public',
        1000,
        'Loans and Refinance',
        1000,
        'Credit Repair',
        1000,
        'Translations',
        1000,
        'Buy and Sell Properties',
        1000,
        'ITIN# Processing',
        1000,
        'Hablamos Español',
        1000
      ]}
      wrapper="span"
      speed={50}
      style={{ fontSize: '1.5em', display: 'inline-block' }}
      repeat={Infinity}
    />
  );
};

export default ServicesAnimation;