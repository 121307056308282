import { ReactElement, FC } from "react";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";

const Services: FC<any> = (): ReactElement => {

    const theme = useTheme();

    return (
        <Box sx={{
            flexGrow: 1,
            backgroundColor: theme.palette.grey[500],
            padding: { xs: 2, md: 5 }, // Adjust padding for different screen sizes
        }}>
            <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{
                    flexDirection: "row",
                    [theme.breakpoints.down("xs")]: {
                        flexDirection: "column"
                    }
                }}
            >

                <Grid item xs={12} sm={6}>
                    <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'white' }}>Send US a message</Typography>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'white' }}>Envíanos un mensaje</Typography>

                    <Grid
                        container
                        spacing={2}
                        marginTop={5}>
                        <Grid item md={6}>
                            <Typography variant="body1"> Main Office </Typography>
                            <Typography variant="body1">  3070 W. Lincoln Ave Ste M </Typography>
                            <Typography variant="body1"> Anaheim, CA 92801 </Typography>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white', marginTop: 5 }} gutterBottom> Parking available. </Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant="body1"> Tel. Office (714) 828-9165 </Typography>
                            <Typography variant="body1">  Tel. Mobile (714) 906-115 </Typography>
                            <Typography variant="body1" gutterBottom> Email: teremed@sbcglobal.net </Typography>
                        </Grid>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'left', marginTop: 2 }}>
                        <Button variant="outlined" color="primary" href="mailto:teremed@sbcglobal.net">Consult Now</Button>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Box
                        component="img"
                        sx={{
                            marginTop: 5,
                            height: 500,
                            width: 464
                        }}
                        src="../images/contact_1.png"
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Services;