
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Mission from "./pages/Mission";

// other
import {FC} from "react";
import Testimonials from "./pages/Testimonials";
import Contact from "./pages/Contact";

// interface
interface Route {
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    component: FC<{}>
}

export const routes: Array<Route> = [
    {
        key: 'home-route',
        title: 'Home',
        path: '/',
        enabled: true,
        component: Home
    },
    {
        key: 'about-route',
        title: 'About',
        path: '/about',
        enabled: true,
        component: About
    },
    {
        key: 'mission-route',
        title: 'Mission',
        path: '/mission',
        enabled: true,
        component: Mission
    },
    {
        key: 'services-route',
        title: 'Services',
        path: '/services',
        enabled: true,
        component: Services
    },
    {
        key: 'testimonials-route',
        title: 'Testimonials',
        path: '/testimonials',
        enabled: true,
        component: Testimonials
    },
    {
        key: 'contact-route',
        title: 'Contact',
        path: '/contact',
        enabled: true,
        component: Contact
    }
]