
import { Box, Grid, Typography, Divider, Paper, Chip } from "@mui/material";
import MissionBox from "../components/MissionBox";

const Mission = () => {
    return (
        <Grid container columnSpacing={10} sx={{
            flexGrow: 1,
            backgroundColor: 'whitesmoke',
            padding: { xs: 2, md: 5 }, // Adjust padding for different screen sizes
        }}>
            <MissionBox
                title="Why work with Teresa"
                text1="Expertise Across Multiple Services"
                text2="Bilingual Proficiency"
                text3="Credentials & ​Certifications"
                text4="Personalized & Certified ​Solutions"
                picture_alt="Teresa in front of a house"
                picture_url="../images/mission_1.jpg" />

            <MissionBox
                title="Por qué trabajar con Teresa"
                text1="Experiencia en Múltiples ​Servicios"
                text2="Competencia Bilingüe"
                text3="Credenciales y ​Certificaciones"
                text4="Soluciones Personalizadas ​y Certificadas"
                picture_alt="Teresa working at her desk"
                picture_url="../images/mission_2.jpg" />

        </Grid>
    );
};

export default Mission;
