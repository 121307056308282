import { ReactElement, FC } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import ServicesGridItem from "../components/ServicesGridItem";

const Services: FC<any> = (): ReactElement => {

    const theme = useTheme();

    return (
        <Box sx={{
            flexGrow: 1,
            backgroundColor: theme.palette.grey[300],
            padding: { xs: 2, md: 5 }, // Adjust padding for different screen sizes
        }}>
            <Typography variant="h3" align="left">
                Full-service business solutions
            </Typography>

            <Typography variant="h4" align="left" gutterBottom>
                Soluciones empresariales de servicio completo
            </Typography>

            <Grid
                container
                spacing={2}
                sx={{
                    marginTop: 2,
                    flexDirection: "row",
                    [theme.breakpoints.down("xs")]: {
                        flexDirection: "column"
                    }
                }}
            >
                {/* First Grid Item */}
                <ServicesGridItem
                    title="Year-round Income Tax"
                    subtitle="Preparación the impuestos todo el año"
                    text="Offering expert assistance in income tax preparation, filing, and ITIN# processing. Stay compliant with tax laws all year long with our comprehensive support tailored to your needs."
                    svg="../images/tax-svgrepo-com.svg"
                />

                {/* Second Grid Item */}
                <ServicesGridItem
                    title="Notary Public"
                    subtitle="Notaria pública"
                    text="Efficient and reliable notary public services, ensuring the authentication and certification of legal documents with precision and professionalism."
                    svg="../images/notary-office-svgrepo-com.svg"
                />

                {/* Third Grid Item */}
                <ServicesGridItem
                    title="Translations"
                    subtitle="Traducciones"
                    text="Meticulous and culturally nuanced translation services, bridging language gaps seamlessly to ensure accurate and effective communication across a diverse range of documents and contexts."
                    svg="../images/conversation-svgrepo-com.svg"
                />

                {/* Fourth Grid Item */}
                <ServicesGridItem
                    title="Real Estate & Loans"
                    subtitle="Bienes Raíces y Préstamos"
                    text="Comprehensive real estate and loan services, guiding clients through seamless property transactions and providing expert assistance in loans and refinancing with a commitment to excellence and financial well-being."
                    svg="../images/real-state-seller-svgrepo-com.svg"
                />
            </Grid>
        </Box>
    );
};

export default Services;